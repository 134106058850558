import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePaths} from './route-paths';
import {SuspenseContainer} from '../../components/suspense/Suspense';
import {ROLES} from '../../features/auth/store/auth.constants';
import {accessControl, loginPageLoader} from '../../features/auth/store/auth.sagas';
import {emailVerificationLoaderSaga} from '../../features/auth/store/emailVerification.sagas';
import {BankRoutes} from '../../features/bank/utils/bank-routes';
import {getFinancialStatementsForFreelancerSaga} from '../../features/company-profile/modules/accounting/store/accounting.saga';
import {PAST_YEAR} from '../../features/company-profile/modules/accounting/utils/constants';
import {loadContractData} from '../../features/contract/store/contract.saga';
import {dashboardV3LoaderSaga} from '../../features/dashboard-v3/store/dashboard-v3.saga';
import DashboardDetails from '../../features/dashboard/components/DashboardDetails';
import {loadDepositOfAccountsDocumentsSaga, loadUnsignedDocumentsSaga} from '../../features/document/modules/database/store/database.saga';
import {DocumentSigningWrapper} from '../../features/document/modules/signing/components/DocumentSigningWrapper';
import {
    clearSignableDocumentsSaga,
    isSigningAdditionalDocumentsSaga,
} from '../../features/document/modules/signing/store/signing.saga';
import {SIGN_TYPES} from '../../features/document/modules/signing/utils/constants';
import {getGlobalPopularArticlesSaga} from '../../features/documentation/store/documentation.saga';
import {DocumentationRoutes} from '../../features/documentation/util/documentation-routes';
import NotFoundView from '../../features/err-pages/components/NotFoundView';
import {getFinancialStatementsFlow} from '../../features/financial-statements/store/financial-statements.saga';
import {loadOnboardingData} from '../../features/freelancer/modules/onboarding/store/onboarding.saga';
import {getAdminInvoiceWorker} from '../../features/invoicing/store/invoice.saga';
import {AriaValidationRoutes} from '../../features/invoicing/util/aria-validation-routes';
import {InvoicingRoutes} from '../../features/invoicing/util/invoicing-routes';
import {
    getAllProfessionAndSpecializationValuesWorker,
    getProfessionAndSpecializationWorker,
} from '../../features/job-profession-and-specilties/store/profession-and-specialization.saga';
import {MissionSearch} from '../../features/opportunity/components/MissionSearch';
import {
    applyToOpportunityFromEmailLinkWorker,
    getAdminClusterWorker,
    getFreelancerOpportunityClusterWorker,
    getFreelancerOtherOpportunityClusterWorker,
    getFreelancerSpecialisationsWorker,
    getMyBidsListWorker,
} from '../../features/opportunity/store/opportunity.saga';
import {
    resetSimulationInputs, resetSimulationResults,
    simulationsListLoaderSaga,
} from '../../features/revenue-simulator/store/revenue-simulator.saga';
import {settingsLoaderSaga} from '../../features/settings/store/settings.sagas';
import {AdministratorSidebar} from '../../features/sidebar/components/AdministratorSidebar/AdministratorSidebar';
import {loadSignature} from '../../features/signature/store/signature.saga';
import {getTrainingInfoWorker} from '../../features/training-bo/store/bo-training.saga';
import {trainingLoaderSaga} from '../../features/training/store/training.saga';
import {getLoggedInUserAccountFlow} from '../../features/user/modules/logged-in-user/store/logged-in-user.saga';
import {loadUsers} from '../../features/user/modules/user-select/store/user-select.saga';
import {vatDeclarationLoaderSaga} from '../../features/vat-declaration/store/vat-declaration.saga';
import {AppLayout} from '../../layout/AppLayout/AppLayout';
import {AppLayoutV3} from '../../layout/AppLayoutV3/AppLayoutV3';
import {DepositOfAccountsLayout} from '../../layout/CustomAppLayouts/DepositOfAccountsLayout';
import {ChangePasswordScreen} from '../../screens/change-password/ChangePasswordScreen';
import AdminOpportunityList from '../../screens/cluster/AdminOpportunityList';
import FreelancerOpportunityList from '../../screens/cluster/FreelancerOpportunityList';
import OpportunityApplyScreen from '../../screens/cluster/OpportunityApplyScreen';
import SubmitOpportunityScreen from '../../screens/cluster/SubmitOpportunity';
import SubmitOpportunitySuccessScreen from '../../screens/cluster/SubmitOpportunitySuccessScreen';
import {CompanyDocumentsScreen} from '../../screens/documents/CompanyDocumentsScreen';
import {DocumentsDatabaseScreen} from '../../screens/documents/DocumentsDatabase';
import DepositOfAccountsFlowScreen from '../../screens/formalities/deposit-of-accounts/DepositOfAccountsFlowScreen';
import {FormalitiesScreen} from '../../screens/formalities/FormalitiesScreen';
import {MyCompaniesScreen} from '../../screens/my-companies/MyCompaniesScreen';
import {MyCompanyScreen} from '../../screens/my-companies/MyCompanyScreen';
import {MyProfileEditScreen} from '../../screens/my-profile/MyProfileEditScreen';
import {MyProfileScreen} from '../../screens/my-profile/MyProfileScreen';
import RevenueSimulationsListScreen from '../../screens/revenue-simulator/RevenueSimulationsListScreen';
import RevenueSimulatorCreateScreen from '../../screens/revenue-simulator/RevenueSimulatorCreateScreen';
import RevenueSimulatorResultsScreen from '../../screens/revenue-simulator/RevenueSimulatorResultsScreen';
import {SettingsScreen} from '../../screens/settings/SettingsScreen';
import TestJoyUi from '../../screens/test/TestJoyUi';
import TestMixUi from '../../screens/test/TestMixUi';
import TrainingScreen from '../../screens/training/TrainingScreen';
import {UserRoles} from '../../utils/user-roles';
import {appLoaderSaga} from '../../v1/app/appLoader.sagas';
import {companyLoaderSaga} from '../../v1/app/company/company.sagas';
import {companyDashboardLoaderSaga} from '../../v1/app/company/companyDashboard/companyDashboard.sagas';
import {companyDocumentsLoaderSaga} from '../../v1/app/company/companyDocuments/companyDocuments.sagas';
import {companyListLoaderSaga} from '../../v1/app/company/companyList/companyList.sagas';
import {businessAllowanceLoaderSaga} from '../../v1/app/expenses/businessAllowance/businessAllowance.sagas';
import {homeOfficeRestrictionSaga, travelLoaderSaga} from '../../v1/app/expenses/expenses.sagas';
import {personalExpensesLoaderSaga} from '../../v1/app/expenses/personalExpenses/personalExpenses.sagas';
import {professionalExpensesLoaderSaga} from '../../v1/app/expenses/professionalExpenses/professionalExpenses.sagas';
import {loadMyCompany} from '../../v1/app/my-companies/myCompanies.sagas';
import {userLoaderSaga, usersLoaderSagas} from '../../v1/app/user/user.sagas';
import {DOCUMENT_CONTEXTS} from '../../v1/config/constants/documentConstants';
// TODO:RELEASE: See if we can code split the FreelancerOnboardingSidebar too

// TODO:MEDIUM: Investigate how to dynamically load sagas for routes that are code split (and reducers)

const CreateUserView = React.lazy(() => import('../../screens/create-user-screen/components/CreateUserScreen'));
const UsersView = React.lazy(() => import('../../screens/user-list/UserList'));
const UserView = React.lazy(() => import('../../v1/components/user/UserView/UserView'));
const Onboarding = React.lazy(() => import('../../screens/onboarding-screen/components/OnboardingScreen'));
const CompaniesInCreationScreen = React.lazy(() => import('../../screens/companies-in-creation/components/CompaniesInCreation'));
const DashboardView = React.lazy(() => import('../../features/dashboard/components/DashboardView/DashboardView'));
const BusinessAllowanceView = React.lazy(() => import('../../v1/components/expenses/businessAllowance/BusinessAllowanceView/BusinessAllowanceView'));
const LoginForm = React.lazy(() => import('../../features/auth/components/LoginForm'));
const NewPasswordForm = React.lazy(() => import('../../features/auth/components/NewPasswordForm'));
const ResetPassword = React.lazy(() => import('../../features/auth/components/ResetPassword/ResetPassword'));
const EmailVerificationForm = React.lazy(() => import('../../features/auth/components/EmailVerificationForm'));
const BackofficeView = React.lazy(() => import('../../v1/components/company/BackofficeView/BackofficeView'));
const CompanyView = React.lazy(() => import('../../v1/components/company/company-profile/CompanyProfileView'));
const CompanyDashboardView = React.lazy(() => import('../../v1/components/company/CompanyDashboardView/CompanyDashboardView'));
const CompanyListView = React.lazy(() => import('../../v1/components/company/CompanyListView/CompanyListView'));
const QuickBooksView = React.lazy(() => import('../../v1/components/company/QuickBooksView/QuickBooksView')); // TODO STUPAR: Do not remove this, should be renamed
const AccessDeniedView = React.lazy(() => import('../../features/err-pages/components/AccessDeniedView'));
const HomeOfficeTravelView = React.lazy(() => import('../../v1/components/expenses/businessAllowance/HomeOfficeTravelView'));
const ProfessionalTravelView = React.lazy(() => import('../../v1/components/expenses/businessAllowance/ProfessionalTravelView'));
const PersonalExpensesView = React.lazy(() => import('../../v1/components/expenses/personal/PersonalExpensesView/PersonalExpensesView'));
const ProfessionalExpensesView = React.lazy(() => import('../../v1/components/expenses/professional/ProfessionalExpensesView/ProfessionalExpensesView'));
const LegalPageADP = React.lazy(() => import('../../screens/legal/ADP'));
const LegalPageCGVS = React.lazy(() => import('../../screens/legal/CGVS'));
const IRTutorialPDF = React.lazy(() => import('../../screens/ir-tutorial-view-pdf/IrTutorialViewPdf'));
const RemunerationExplViewPdf = React.lazy(() => import('../../screens/remuneration-expl-view-pdf/RemunerationExplViewPdf'));
const InvoicingAdminViewScreen = React.lazy(() => import('../../screens/invoicing/InvoicingAdminViewScreen'));
const WealthManagementScreen = React.lazy(() => import('../../screens/wealth-management/WealthManagementScreen'));
const PerSimulatorScreen = React.lazy(() => import('../../screens/wealth-management/PerSimulatorScreen'));
const VieSimulatorScreen = React.lazy(() => import('../../screens/wealth-management/VieSimulatorScreen'));
const FinancialStatements = React.lazy(() => import('../../features/financial-statements/components/FinancialStatements'));
const VatDeclaration = React.lazy(() => import('../../features/vat-declaration/VatDeclaration'));
// const ScpiSimulatorScreen = React.lazy(() => import('../../screens/wealth-management/ScpiSimulatorScreen'));

const PublicRoutes = [
    {
        element: <NotFoundView />,
        path: '*',
    },
    {
        element: <LoginForm />,
        path: RoutePaths.LOGIN,
        locationChangeSideEffects: [
            [loginPageLoader],
        ],
    },
    {
        element: <NewPasswordForm />,
        path: RoutePaths.NEW_PASSWORD,
        locationChangeSideEffects: [],
    },
    {
        element: <ResetPassword />,
        path: RoutePaths.RESET_PASSWORD,
        locationChangeSideEffects: [],
    },
    {
        element: <EmailVerificationForm />,
        path: RoutePaths.EMAIL_VERIFICATION,
        locationChangeSideEffects: [
            [emailVerificationLoaderSaga],
        ],
    },
    {
        element: <AccessDeniedView />,
        path: RoutePaths.ACCESS_DENIED,
        locationChangeSideEffects: [
            [appLoaderSaga],
        ],
    },
    {
        path: RoutePaths.REVENUE_SIMULATOR_RESULTS_PUBLIC,
        element: <RevenueSimulatorResultsScreen isPublic />,
    },
    ...AriaValidationRoutes,
];

const CommonRoutes = [
    {
        element: <SettingsScreen />,
        path: RoutePaths.SETTINGS,
        locationChangeSideEffects: [
            [appLoaderSaga],
        ],
    },
    {
        path: RoutePaths.MY_PROFILE,
        element: <MyProfileScreen />,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [loadContractData],
            [getAllProfessionAndSpecializationValuesWorker],
            [getProfessionAndSpecializationWorker],
        ],
    },
    {
        path: RoutePaths.MY_PROFILE_EDIT,
        element: <MyProfileEditScreen />,
        locationChangeSideEffects: [
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.CHANGE_PASSWORD,
        element: <ChangePasswordScreen />,
    },
    {
        path: RoutePaths.IR_TUTORIAL_PDF,
        element: <IRTutorialPDF />,
        locationChangeSideEffects: [
            [settingsLoaderSaga],
        ],
    },
    {
        path: RoutePaths.REMUNERATION_EXPLANATION_DOCUMENT,
        element: <RemunerationExplViewPdf />,
        locationChangeSideEffects: [
            [settingsLoaderSaga],
        ],
    },
    // TODO Remove before 1st september
    {
        path: '/test2/joyui',
        element: <TestJoyUi />,
    },
    // TODO Remove before 1st september
    {
        path: '/test2/mixui',
        element: <TestMixUi />,
    },
];

const FreelancerRoutes = () => [
    {
        path: RoutePaths.CGVS,
        element: <LegalPageCGVS />,
        exact: true,
    },
    {
        path: RoutePaths.ADP,
        element: <LegalPageADP />,
        exact: true,
    },
    {
        element: <AppLayoutV3 suspenseFallback={<SuspenseContainer />} />,
        children: [
            {
                element: <DashboardView />,
                path: RoutePaths.DASHBOARD,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    // TODO: Verify if it is safe to remove dashboardLoaderSaga in favor of DashboardV3LoaderSaga
                    // [dashboardLoaderSaga],
                    [dashboardV3LoaderSaga],
                    [clearSignableDocumentsSaga],
                    [companyDocumentsLoaderSaga, {
                        documentContext: DOCUMENT_CONTEXTS.PENDING_SIGNING,
                    }],
                    [getFinancialStatementsForFreelancerSaga, {year: PAST_YEAR}],
                    [getGlobalPopularArticlesSaga],
                    // TODO This kills route locationChangeSideEffects flow by setting new user for routerFn
                    // It is needed for remuneration for now
                    [getLoggedInUserAccountFlow],
                ],
            },
        ],
    },
    {
        element: <AppLayout suspenseFallback={<SuspenseContainer />} />,
        children: [
            ...CommonRoutes,
            ...BankRoutes,
            ...InvoicingRoutes,
            ...DocumentationRoutes,
            {
                element: <DashboardDetails />,
                path: RoutePaths.DASHBOARD_DETAILS,
            },
            {
                path: RoutePaths.CLUSTER,
                exact: true,
                element: <FreelancerOpportunityList />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                    [getFreelancerOpportunityClusterWorker],
                    [getFreelancerOtherOpportunityClusterWorker],
                    [getMyBidsListWorker],
                ],
            },
            {
                path: RoutePaths.CLUSTER_SUBMIT,
                exact: true,
                element: <SubmitOpportunityScreen />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                    [getFreelancerSpecialisationsWorker],
                ],
            },
            {
                path: RoutePaths.CLUSTER_SUBMIT_SUCCESS,
                exact: true,
                element: <SubmitOpportunitySuccessScreen />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                ],
            },
            {
                path: RoutePaths.CLUSTER_APPLY,
                exact: true,
                element: <OpportunityApplyScreen />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                    [applyToOpportunityFromEmailLinkWorker],
                ],
            },
            {
                element: <Onboarding />,
                path: RoutePaths.ONBOARDING,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [settingsLoaderSaga],
                    [loadOnboardingData],
                ],
            },
            {
                path: RoutePaths.MY_COMPANIES,
                element: <MyCompaniesScreen />,
            },
            {
                path: RoutePaths.MY_COMPANY,
                element: <MyCompanyScreen />,
                locationChangeSideEffects: [
                    [loadMyCompany],
                ],
            },
            {
                path: RoutePaths.DOCUMENTS,
                element: <DocumentsDatabaseScreen />,
                exact: true,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER, ROLES.CARE],
                        isPrivate: true,
                    }],
                    [companyDocumentsLoaderSaga, {
                        documentContext: DOCUMENT_CONTEXTS.DATABASE,
                    }],
                ],
            },
            {
                path: RoutePaths.MISSION_SEARCH,
                element: <MissionSearch />,
                exact: true,
            },
            {
                path: RoutePaths.PROFESSIONAL_TRAVEL,
                element: <ProfessionalTravelView />,
                exact: true,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER, ROLES.CARE],
                        isPrivate: true,
                    }],
                    [travelLoaderSaga],
                ],
            },
            {
                path: RoutePaths.PROFESSIONAL_EXPENSES,
                element: <ProfessionalExpensesView />,
                exact: true,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [professionalExpensesLoaderSaga],
                ],
            },
            {
                path: RoutePaths.BUSINESS_KILOMETERS_ALLOWANCE,
                exact: true,
                element: <BusinessAllowanceView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER, ROLES.CARE],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [settingsLoaderSaga],
                    [businessAllowanceLoaderSaga],
                ],
            },
            {
                path: RoutePaths.HOME_OFFICE_TRAVEL,
                exact: true,
                element: <HomeOfficeTravelView />,
                locationChangeSideEffects: [
                    [homeOfficeRestrictionSaga],
                    [travelLoaderSaga],
                ],
            },
            {
                path: RoutePaths.PERSONAL_EXPENSES,
                exact: true,
                element: <PersonalExpensesView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [personalExpensesLoaderSaga],
                ],
            },
            {
                path: RoutePaths.TRAINING,
                exact: true,
                element: <TrainingScreen />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                    [clearSignableDocumentsSaga],
                    [trainingLoaderSaga],
                ],
            },
            {
                path: RoutePaths.SIGN_DOCUMENTS,
                exact: true,
                element: <DocumentSigningWrapper type={SIGN_TYPES.global} />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER, ROLES.CARE],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [clearSignableDocumentsSaga],
                    [isSigningAdditionalDocumentsSaga],
                    [loadUnsignedDocumentsSaga],
                    [loadSignature],
                ],
            },
            {
                path: RoutePaths.SIGN_DEPOSIT_OF_ACCOUNTS_DOCUMENTS,
                exact: true,
                element: <DocumentSigningWrapper type={SIGN_TYPES.global} />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.FREELANCER, ROLES.CARE],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [clearSignableDocumentsSaga],
                    [isSigningAdditionalDocumentsSaga],
                    [getFinancialStatementsForFreelancerSaga, {year: PAST_YEAR}],
                    [loadDepositOfAccountsDocumentsSaga],
                    [loadSignature],
                ],
            },
            {
                path: RoutePaths.WEALTH_MANAGEMENT,
                exact: true,
                element: <WealthManagementScreen />,
            },
            {
                path: RoutePaths.PER_SIMULATOR,
                exact: true,
                element: <PerSimulatorScreen />,
            },
            {
                path: RoutePaths.VIE_SIMULATOR,
                exact: true,
                element: <VieSimulatorScreen />,
            },
            // TODO: Implement at later date when we get the right calculations
            // {
            //     path: RoutePaths.SCPI_SIMULATOR,
            //     exact: true,
            //     element: <ScpiSimulatorScreen />,
            // },
        ],
    },
];

const DepositOfAccountsRoutes = [
    {
        element: <DepositOfAccountsLayout suspenseFallback={<SuspenseContainer />} />,
        children: [
            {
                path: RoutePaths.COMPANY_DEPOSIT_OF_ACCOUNTS,
                exact: true,
                element: <DepositOfAccountsFlowScreen />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [companyLoaderSaga],
                ],
            },
        ],
    },
];

const AdministratorRoutes = [
    {
        element: (
            <AppLayout
                mainSx={{backgroundColor: 'white'}}
                sidebar={<AdministratorSidebar />}
                suspenseFallback={<SuspenseContainer />}
            />
        ),
        children: [
            {
                path: RoutePaths.REVENUE_SIMULATOR_RESULTS,
                exact: true,
                element: <RevenueSimulatorResultsScreen />,
                locationChangeSideEffects: [
                    [resetSimulationResults],
                ],
            },
        ],
    },
    {
        // eslint-disable-next-line max-len
        element: <AppLayout sidebar={<AdministratorSidebar />} suspenseFallback={<SuspenseContainer />} />,
        children: [
            ...CommonRoutes,
            {
                path: RoutePaths.COMPANY_DOCUMENTS,
                exact: true,
                element: <CompanyDocumentsScreen />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [companyDocumentsLoaderSaga, {
                        documentContext: DOCUMENT_CONTEXTS.DATABASE,
                    }],
                    [appLoaderSaga],
                ],
            },
            {
                index: true,
                element: <UsersView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [usersLoaderSagas],
                ],
            },
            {
                path: RoutePaths.CREATE_USER,
                element: <CreateUserView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.ADMINISTRATOR],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [loadUsers, {role: [UserRoles.ADMINISTRATOR]}],
                    [getAllProfessionAndSpecializationValuesWorker],
                ],
            },
            {
                path: RoutePaths.USER,
                children: [
                    {
                        element: <UserView />,
                        index: true,
                    },
                    {
                        path: RoutePaths.USER_TAB,
                        element: <UserView />,
                        locationChangeSideEffects: [
                            [loadUsers, {role: UserRoles.ADMINISTRATOR}],
                            [getAllProfessionAndSpecializationValuesWorker],
                        ],
                    },
                    {
                        path: RoutePaths.EDIT_USER,
                        element: <UserView />,
                    },
                ],
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [userLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY,
                element: <CompanyView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [companyLoaderSaga],
                    [settingsLoaderSaga],
                ],
            },
            // TODO DO this with conditional :tab?
            {
                path: RoutePaths.COMPANY_WITH_TAB,
                element: <CompanyView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [companyLoaderSaga],
                    [settingsLoaderSaga],
                    [getTrainingInfoWorker],
                ],
            },
            {
                path: RoutePaths.COMPANIES,
                element: <CompanyListView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.ADMINISTRATOR],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [companyListLoaderSaga],
                ],
            },
            {
                path: RoutePaths.ONBOARDING_COMPANIES,
                element: <CompaniesInCreationScreen />,
                locationChangeSideEffects: [
                    // [getCompaniesInCreationWorker],
                    // [getUsers],
                    // [settingsLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_BUSINESS_KILOMETERS_ALLOWANCE,
                exact: true,
                element: <BusinessAllowanceView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.ADMINISTRATOR],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [businessAllowanceLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_BACKOFFICE,
                exact: true,
                element: <BackofficeView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_DASHBOARD,
                exact: true,
                element: <CompanyDashboardView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                            // ROLES.FREELANCER,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [companyDashboardLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_QUICKBOOKS, // TODO STUPAR: Do not remove this, should be renamed
                exact: true,
                element: <QuickBooksView />, // TODO STUPAR: Do not remove this, should be renamed
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_PERSONAL_EXPENSES,
                exact: true,
                element: <PersonalExpensesView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.ADMINISTRATOR],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [personalExpensesLoaderSaga],
                ],
            },
            {
                path: RoutePaths.COMPANY_PROFESSIONAL_EXPENSES,
                exact: true,
                element: <ProfessionalExpensesView />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [ROLES.ADMINISTRATOR],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [professionalExpensesLoaderSaga],
                ],
            },
            {
                path: RoutePaths.CLUSTER,
                exact: true,
                element: <AdminOpportunityList />,
                locationChangeSideEffects: [
                    [getAdminClusterWorker],
                ],
            },
            {
                path: RoutePaths.COMPANY_INVOICING,
                exact: true,
                element: <InvoicingAdminViewScreen />,
                locationChangeSideEffects: [
                    [accessControl, {
                        roles: [
                            ROLES.ADMINISTRATOR,
                        ],
                        isPrivate: true,
                    }],
                    [appLoaderSaga],
                    [getAdminInvoiceWorker],
                ],
            },
            {
                path: RoutePaths.FORMALITIES,
                exact: true,
                element: <FormalitiesScreen />,
                children: [
                    {
                        path: '',
                        // TODO: HPD-5014 - change to VAT_DECLARATION
                        element: <Navigate to={RoutePaths.FINANCIAL_STATEMENTS} replace />,
                    },
                    {
                        path: RoutePaths.VAT_DECLARATION,
                        element: <VatDeclaration />,
                        locationChangeSideEffects: [
                            [appLoaderSaga],
                            [vatDeclarationLoaderSaga],
                        ],
                    },
                    {
                        path: RoutePaths.FINANCIAL_STATEMENTS,
                        element: <FinancialStatements />,
                        locationChangeSideEffects: [
                            [appLoaderSaga],
                            [getFinancialStatementsFlow],
                        ],
                    },
                ],
            },
            {
                path: RoutePaths.REVENUE_SIMULATIONS_LIST,
                exact: true,
                element: <RevenueSimulationsListScreen />,
                locationChangeSideEffects: [
                    [appLoaderSaga],
                    [simulationsListLoaderSaga],
                ],
            },
            {
                path: RoutePaths.REVENUE_SIMULATOR_CREATE,
                exact: true,
                element: <RevenueSimulatorCreateScreen />,
                locationChangeSideEffects: [
                    [resetSimulationInputs],
                    [resetSimulationResults],
                ],
            },

        ],
    },
];

export const Routes = {
    PublicRoutes,
    AdministratorRoutes,
    FreelancerRoutes,
    DepositOfAccountsRoutes,
};

