import {faScaleUnbalanced} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ComparisonTable} from './ComparisonTable';
import Section from '../../../../../../components/joy-ui/sections/Section';

export const ComparisonTableSection = () => {
    const {t} = useTranslation('revenueSimulator');
    return (
        <Section title={t('comparisonTable.title')} bodyStyles={{overflowX: 'auto'}} icon={faScaleUnbalanced}>
            <ComparisonTable />
        </Section>
    );
};
