import {Box, Button, Stack, ToggleButtonGroup} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

export const TabsBlock = ({children, onTabChange, beforeTabs}) => {
    const [activeTab, setActiveTab] = useState(React.Children.toArray(children)[0].props.title);

    useEffect(() => {
        onTabChange?.(activeTab);
    }, [activeTab, onTabChange]);

    const renderTabButtons = () => {
        return React.Children.map(children, child => (
            <Button
                key={child.props.title}
                disabled={child.props.isDisabled}
                variant={activeTab === child.props.title ? 'solid' : 'plain'}
                value={child.props.title}
                color={activeTab === child.props.title ? 'primary' : 'neutral'}
                sx={theme => ({
                    'px': 1,
                    'color': 'neutral.softColor',
                    'fontWeight': 700,
                    'whiteSpace': 'nowrap',
                    '&[aria-pressed="true"]': {
                        backgroundColor: 'primary.softColor',
                        color: 'background.body',
                    },
                    '--variant-plainHoverBg': theme.vars.palette.linen[200],
                    'minHeight': '24px',
                    'height': '24px',
                    'letterSpacing': '0.42px',
                })}
            >
                {child.props.title}
            </Button>
        ));
    };

    const renderActiveTabContent = () => {
        return React.Children.map(children, child => {
            if (child.props.title === activeTab) return child.props.children;
            return null;
        });
    };

    return (
        <Box flex={1}>
            <Stack direction={{mobile: 'column', desktop: 'row'}} alignItems="center" spacing={2}>
                {beforeTabs}
                <ToggleButtonGroup
                    variant="plain"
                    color="primary"
                    value={activeTab.toString()}
                    size="sm"
                    onChange={(_, newValue) => {
                        if (newValue !== null) {
                            setActiveTab(newValue);
                        }
                    }}
                    spacing={0.5}
                    buttonFlex={1}
                    sx={{
                        'backgroundColor': 'background.surface',
                        'borderRadius': 'xxl',
                        'boxShadow': 'xs',
                        'overflowX': 'auto',
                        '&::-webkit-scrollbar': {display: 'none'},
                        'flex': 1,
                        'width': '100%',
                    }}
                >
                    {renderTabButtons()}
                </ToggleButtonGroup>
            </Stack>
            <Box mt={2}>{renderActiveTabContent()}</Box>
        </Box>
    );
};

TabsBlock.propTypes = {
    onTabChange: PropTypes.func,
    beforeTabs: PropTypes.node,
};

TabsBlock.defaultProps = {
    onTabChange: () => {},
    beforeTabs: undefined,
};

export const Tab = ({children}) => {
    return <>{children}</>;
};

Tab.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isDisabled: PropTypes.bool,
};

Tab.defaultProps = {
    isDisabled: false,
};
