import {Box, useTheme} from '@mui/joy';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {useChartColors} from './utils';
import {formatCompactNumber, formatOrdinalNumber} from '../../../../../wealth-management/utils/helpers';
import {useSimulationData} from '../../utils/useSimulationData';

const STROKE_COLOR = '#E8E9EA';

const BarShape = props => {
    const {arce, withHiway, withoutHiway} = props.payload;

    const barCount = [arce, withHiway, withoutHiway].filter(Boolean).length;
    const x = props.x + (3 - barCount) * 8; // for each bar offset by 8px so they appear centered
    const y = props.y + 1;

    return <Rectangle {...props} x={x} y={y} radius={[7, 7, 4, 4]} stroke="#ffffff" strokeWidth={2} />;
};

export const RoadmapChart = () => {
    const simulation = useSimulationData();
    const graph = simulation.simulationOutput.compensationRoadmap.graph;
    const {i18n} = useTranslation();

    const ARCE = simulation.simulationOutput.ACRE;
    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;

    const data = useMemo(
        () => Array(13)
            .fill()
            .map((_, i) => ({
                month: i,
                withHiway: i === 0 ? 0 : graph.withHiway[i],
                withoutHiway: graph.withoutHiway[i],
                ...(i === 2 + months || i === 8 + months ? {arce: ARCE / 2} : {}),
            })),
        [graph, ARCE, months],
    );

    const theme = useTheme();
    const colors = useChartColors();

    const xTick = {
        fontSize: '12px',
        fontFamily: 'Avantt',
        fontWeight: 600,
        dy: 20,
    };

    const yTick = {
        ...xTick,
        fill: theme.palette.text.tertiary,
        opacity: 0.5,
    };

    const axisLine = {
        stroke: STROKE_COLOR,
    };

    return (
        <>
            <Box position="relative" width="100%" paddingBottom="352px">
                <Box position="absolute" left={0} top={0} right={0} bottom={0}>
                    <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 0,
                                left: 10,
                                bottom: 30,
                            }}
                            barSize={14}
                            barGap={2}
                        >
                            <CartesianGrid vertical={false} stroke={STROKE_COLOR} />
                            <YAxis
                                tick={yTick}
                                axisLine={{stroke: 'none'}}
                                tickLine={axisLine}
                                tickSize={8}
                                tickMargin={8}
                                tickFormatter={formatCompactNumber}
                            />
                            <Bar shape={BarShape} dataKey="withoutHiway" fill={colors.currentSituation} />
                            <Bar shape={BarShape} dataKey="withHiway" fill={colors.withHiway} />
                            <Bar shape={BarShape} dataKey="arce" fill={colors.arce} />
                            <XAxis
                                dataKey="month"
                                tick={xTick}
                                axisLine={axisLine}
                                tickLine={false}
                                tickFormatter={number => formatOrdinalNumber(number, i18n.language)}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </>
    );
};
